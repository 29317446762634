<template>
  <div class="readme-article">
    <h1 id="功能简介">一、功能简介</h1>
    <h2 id="引导页">1.引导页</h2>
    <p>
      展示企业形象，头部企业背景、logo展示，下方显示历史访问过的名片列表，点击名片可跳转至名片详情。
    </p>
    <p>
      <img src="@/assets/img/card/0-1.jpg" />
    </p>
    <h2 id="名片">2.名片</h2>
    <p>
      名片详情展示，包括企业名称、姓名、联系电话、地址、微信、个人简介及风采展示等信息；支持一键拨号、一键复制、查看地址、分享名片、同步到通讯录等功能；支持关联其他小程序，可一键跳转至其他小程序；
    </p>
    <p>
      <img src="@/assets/img/card/0-2.jpg" />
    </p>
    <h2 id="产品">3.产品</h2>
    <p>
      公司产品列表展示，包含产品图片、产品名称及产品简介，点击查看产品详情。
    </p>
    <p>
      <img src="@/assets/img/card/0-3.jpg" />
    </p>
    <h2 id="动态">4.动态</h2>
    <p>
      公司动态列表展示，顶部可切换动态分类，包含动态图片、动态名称及动态简介，点击查看动态详情。
    </p>
    <p>
      <img src="@/assets/img/card/0-4.jpg" />
    </p>
    <h2 id="试用">5.试用</h2>
    <p>
      自定义表单，后台可自定义表单内容，用户提交内容后会在后台展示。
    </p>
    <p>
      <img src="@/assets/img/card/0-5.jpg" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card0-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
img{
  width: 350px;
}
</style>